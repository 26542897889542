<!-- ................................................................................ -->
<!-- https://demos.creative-tim.com/argon-dashboard-pro/pages/dashboards/landing.html -->

<template>
  <div id="app">
    <router-view class="..." />
  </div>
</template>

<script>
export default {
  name: 'App',
  beforeCreate() {
    if (location.hostname === "localhost" || location.hostname === "127.0.0.1") {
      console.log("%c It's a local server! ", 'background: #222; color: #bada55');
    } else {
      // if (location.protocol !== 'https:') { /* redirect to https */
      //   location.replace(`https:${location.href.substring(location.protocol.length)}`);
      // }
    }
  },
}
</script>

<style>
@import './has-tooltip.css';
@import url(//fonts.googleapis.com/css2?family=Prompt:wght@300&display=swap);

#app {
  font-family: 'Prompt', sans-serif !important;
}

.card .card-body {
  font-family: 'Prompt', sans-serif !important;
}

.modal.show {
  display: block !important;
}

.w-100 {
  width: 100% !important;
}

.text-center {
  align-items: center !important;
}

.cursor_pointer {
  cursor: pointer !important;
}

.swal-text {
  text-align: center !important;
}

.swal-footer {
  text-align: center !important;
}

.float-right {
  float: right !important;
}

.show-mobile {
  display: none !important;
}

@media screen and (max-width: 767px) {

  /* (max-width: 600px) */
  .hidden-mobile {
    display: none !important;
  }

  .show-mobile {
    display: block !important;
  }
}

/* ................................................. */
.transform-none {
  text-transform: none !important;
}

.cut-text {
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  white-space: nowrap !important;
}

.photo-album {
  width: 100%;
  height: 100%;
  object-fit: cover !important;
  /* height: 175px !important; */
}

.photo-cover {
  width: 100%;
  height: 375px !important;
  object-fit: cover !important;
}

@media screen and (max-width: 992px) {

  /* (max-width: 767px) */
  .photo-cover {
    height: 275px !important;
  }
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.avatar-3xl {
  width: 245px !important;
  height: 245px !important;
}

.blur {
  background-color: rgba(245, 245, 245, 1);
  opacity: .05;
}

.dropdown-menu-right {
  right: 0;
  left: auto
}

.dropup-right .dropdown-menu:after {
  left: auto !important;
  right: 10px !important;
}

@media (min-width: 576px) {
  .modal-lgx {
    max-width: 600px
  }
}

.bg-error {
  background: #ffed00 !important;
  color: black;
}
</style>